/* Login.css */
.login-background {
  background-image: url('https://t4.ftcdn.net/jpg/05/51/94/81/360_F_551948178_rcf4rEVLcVRCZXAPFIiZCYQmBqeD8sQD.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.8; /* Adjust the opacity to set visibility */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.login-container {
  background: rgba(255, 255, 255, 0.8); /* Optional: Set a background with some transparency for the login container */
  border-radius: 10px;
  position: relative;
}


.gradient-form {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container {
    width: 100%;
    max-width: 1000px;
    overflow: hidden;
}

.login-form {
    padding: 2rem;
}

.logo2 {
    width: 150px; /* Increased from 100px */
    height: auto; /* This ensures the aspect ratio is maintained*/
    max-width: none;
}

.title {
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.subtitle {
    margin-bottom: 1.5rem;
}

.input-field {
    margin-bottom: 1rem;
}

.login-button {
    background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
    color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.forgot-password {
    margin-bottom: 1rem;
}

.forgot-password a {
    color: #6c757d;
    text-decoration: none;
}

.create-account {
    margin-top: 1rem;
}

.create-button {
    margin-left: 0.5rem;
    border-color: #d8363a;
    color: #d8363a;
}

.info-section {
    background: linear-gradient(to right, #246bee, #3641d8, #3663dd, #456cb4);
    color: white;
}

.info-title {
    font-weight: bold;
    margin-bottom: 1rem;
}

.info-text {
    font-size: 0.9rem;
}

