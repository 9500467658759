@media (max-width: 760px) {
    nav{
        margin: 0px;
    }
}

.whats-app {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 15px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

@media (max-width: 768px) {
  .search-bar {
    width: 70%;
    /* transform: translate(-50%, 50%); */
    margin-top: 60px;
    
  }

  .search-bar .input-group {
    flex-direction: row;
  }

  .search-bar .input-group .form-control {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .search-bar .input-group .btn {
    width: 30%;
    height: 40px;
    margin-top: 20px;
  }
}

